import * as RadixAccordion from '@radix-ui/react-accordion';
import React from 'react';
import { AccordionContent } from './accordion-content';
import { AccordionTrigger } from './accordion-trigger';

export type AccordionItem = {
  value?: string;
  title: string | JSX.Element | React.ReactNode;
  content: string | JSX.Element | React.ReactNode;
};

export type AccordionProps = {
  items: AccordionItem[];
  defaultValue?: string;
};

export const Accordion = ({ items, defaultValue }: AccordionProps) => {
  return (
    <RadixAccordion.Root className="flex flex-col gap-[1px]" collapsible defaultValue={defaultValue} type="single">
      {items.map((item, index) => (
        <RadixAccordion.Item value={item.value ?? `item-${index}`}>
          <AccordionTrigger title={item.title} />
          <AccordionContent>{item.content}</AccordionContent>
        </RadixAccordion.Item>
      ))}
    </RadixAccordion.Root>
  );
};
