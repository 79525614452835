import * as Accordion from '@radix-ui/react-accordion';
import React from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { Icon } from '../icon/icon';

export type AccordionTriggerProps = { title: string | JSX.Element | React.ReactNode };

export const AccordionTrigger = React.forwardRef<HTMLButtonElement, AccordionTriggerProps>(
  ({ title }: AccordionTriggerProps, forwardedRef) => (
    <Accordion.Trigger
      className="!mb-0 !flex !h-auto !w-full !items-center !justify-between !whitespace-normal !break-normal !py-[8px] !px-[10px] !text-left !leading-[20px]"
      ref={forwardedRef}
    >
      {title}
      <Icon icon={FaChevronDown} className="accordion-chevron" aria-hidden />
    </Accordion.Trigger>
  ),
);
