import { FC, useState } from 'react';
import * as RadixToolTip from '@radix-ui/react-tooltip';
import cx from 'classnames';

export type TextTooltipProps = {
  children: React.ReactNode;
  text: string | React.ReactNode;
  dark?: boolean;
  side?: 'top' | 'bottom' | 'left' | 'right';
  asChild?: boolean;
};

/**
 * @link https://radix-ui.com/primitives/docs/components/tooltip
 */
export const TextTooltip: FC<TextTooltipProps> = ({ children, text, dark, side, asChild = false }) => {
  const [open, setOpen] = useState(false);

  const handleOpenChange = (open: boolean) => setOpen(open);

  const handleClick = () => setOpen((o) => !o);

  return (
    <RadixToolTip.Provider>
      <RadixToolTip.Root onOpenChange={handleOpenChange} open={open}>
        <RadixToolTip.Trigger
          asChild={asChild}
          className="unstyled-button tooltip-trigger"
          onClick={asChild ? handleClick : undefined}
        >
          {children}
        </RadixToolTip.Trigger>
        <RadixToolTip.Portal>
          <RadixToolTip.Content className={cx('tooltip-content z-[1000]', dark && 'dark')} side={side}>
            {text}
            <RadixToolTip.Arrow className="tooltip-arrow" />
          </RadixToolTip.Content>
        </RadixToolTip.Portal>
      </RadixToolTip.Root>
    </RadixToolTip.Provider>
  );
};
